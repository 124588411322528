const Modal = ({ title, children, isOpen, hideClose, onClose }) => {
  return (
    <div className={`custom-modal ${isOpen ? "show" : ""}`}>
      <div className="custom-modal-overlay" onClick={onClose} />
      <div className="custom-modal-container">
        <div className="custom-modal-header d-flex justify-content-between">
          {title && <h3 className="custom-modal-title">{title}</h3>}
          {hideClose ? (
            <></>
          ) : (
            <button className="custom-modal-close" onClick={onClose}>
              <i className="bi bi-x-lg" />
            </button>
          )}
        </div>
        <div className="custom-modal-body">{children}</div>
      </div>
    </div>
  );
};

export default Modal;

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
// page animation
import WOW from "wowjs";
import "../../../assets/css/animate.css";
// image import
import EventThumb1 from "../../../assets/images/banners/head-banner.jpg";
import CollectionArea from "./CollectionArea";
import {
  fetchEventCategories,
  fetchEvents,
  fetchEventTags,
} from "../../../services/events";
import { fetchPromotionBanners } from "../../../services/banners";
import moment from "moment";
import PromotionBannerArea from "./PromotionBannerArea";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { initScripts } from "../../../utils";

const perpage = 10;
const formatPrice = (tickets, currency = "HKD") => {
  const prices = tickets.map((ticket) => ticket.price);
  if (prices.length === 0) {
    return "No Ticket Available";
  }
  const minPrice = Math.min(...prices);
  const maxPrice = Math.max(...prices);
  return minPrice === maxPrice
    ? `${currency} $${minPrice}`
    : `${currency} $${minPrice} - ${currency} $${maxPrice}`;
};
const EventSidebar = () => {
  const [price, setPrice] = React.useState(10000);
  const [events, setEvents] = React.useState([]);
  const [categories, setCategories] = React.useState([]);
  const [tags, setTags] = React.useState([]);
  const [banners, setBanners] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const location = useLocation();
  const query = Object.fromEntries(new URLSearchParams(location.search));

  useEffect(() => {
    const init = async () => {
      new WOW.WOW().init();
      setLoading(true);
      await Promise.all([
        getEventCategories(),
        getEventList(),
        getPromotionBanners(),
        getEventTags(),
      ]);
      setLoading(false);
      setPrice(query?.max_price || 10000);
    };
    init();
  }, [location]);

  useEffect(() => {
    initScripts();
  }, []);

  const paginatedEvents = React.useMemo(() => {
    return events.slice((currentPage - 1) * perpage, currentPage * perpage);
  }, [events, currentPage]);

  const getEventList = async () => {
    try {
      const params = new URLSearchParams(window.location.search);
      let query = {};
      params.forEach((param, key) => {
        if (key === "type" && param === "ALL") return;
        if (key === "category_id" && param === "ALL") return;
        query[key] = param;
      });
      const response = await fetchEvents(query);
      setEvents(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };
  const getEventCategories = async () => {
    try {
      const response = await fetchEventCategories();
      setCategories(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };
  const getEventTags = async () => {
    try {
      const response = await fetchEventTags();
      const tags = response.data.filter((el) => el.hashtags);
      setTags(tags);
    } catch (error) {
      console.error(error);
    }
  };
  const getPromotionBanners = async () => {
    try {
      const response = await fetchPromotionBanners();
      const activeBanner = response.data.filter((el) => el.status === 1);
      setBanners(activeBanner);
    } catch (error) {
      console.log(error);
    }
  };
  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handleImageError = (e) => {
    e.target.onerror = null;
    e.target.src = EventThumb1;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const urlParams = new URLSearchParams(formData);
    window.location.href = `${
      process.env.PUBLIC_URL
    }/event?${urlParams.toString()}`;
  };
  const renderNextEvent = (timeslots) => {
    const nearlyEvent = timeslots.filter((time) =>
      moment(time.start_date).isAfter(moment())
    )[0];
    const latestEvent = timeslots[0];
    if (nearlyEvent) return nearlyEvent.start_date;
    return latestEvent.start_date;
  };
  const handleChangePrice = (e) => {
    setPrice(e.target.value);
  };
  const handleCategoryClick = (category) => {
    const urlParams = new URLSearchParams();
    urlParams.set("category_id", category.id);
    window.location.href = `${
      process.env.PUBLIC_URL
    }/event?${urlParams.toString()}`;
  };
  const handleTagClick = (tag) => {
    const urlParams = new URLSearchParams();
    urlParams.set("tags", tag.hashtags);
    return `${process.env.PUBLIC_URL}/event?${urlParams.toString()}`;
  };
  const handlePageChange = (page) => {
    setCurrentPage((prev) => prev + page);
  };

  const selectedCategory = new URLSearchParams(window.location.search).get(
    "category_id"
  );
  const wowDelay = (index) => 200 * Math.ceil((index + 1) / 2);

  return (
    <>
      {/* ===============  breadcrumb area start =============== */}
      <div className="breadcrumb-area">
        <div className="container">
          <div className="row align-items-end">
            <div className="col-lg-12">
              <div className="breadcrumb-content">
                <div className="page-outlined-text">
                  <h1>Event</h1>
                </div>
                <h2 className="page-title">Events</h2>
                <ul className="page-switcher">
                  <li>
                    <Link onClick={scrollTop} to={`${process.env.PUBLIC_URL}/`}>
                      Home <i className="bi bi-caret-right" />
                    </Link>
                  </li>
                  <li>Events</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ===============  breadcrumb area end =============== */}
      {/* ===============  Event Area start  =============== */}
      <div className="event-sidebar-wrapper ">
        <div className="container pt-96 position-relative">
          <div className="row">
            <div className="col-lg-4">
              <div className="background-title text-style-one">
                <h2>Popular Event</h2>
              </div>
              <div className="event-sidebar">
                <form
                  id="sb-searchbar-form"
                  className="sb-searchbar-form"
                  onSubmit={handleSubmit}
                >
                  <div className="sb-searchbar-input">
                    <input
                      type="text"
                      name="q"
                      defaultValue={query?.q}
                      placeholder="Event Name"
                      className="py-4"
                    />
                  </div>
                  <div className="price-range-wrapper mt-4">
                    <div className="d-flex flex-column align-items-center gap-3">
                      <div className="d-flex gap-2 col-12">
                        <label className="form-label mb-0">Price</label>
                        <input
                          type="range"
                          max={10000}
                          className="form-range price-range-input"
                          value={price}
                          onChange={handleChangePrice}
                        />
                      </div>
                      <div className="d-flex align-items-center gap-2 col-12">
                        <span className="price-range-currency">HKD $</span>
                        <input
                          className="price-range-value col text-center"
                          name="max_price"
                          value={price}
                          onChange={handleChangePrice}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="custom-select time-select filter-options mt-4">
                    <select defaultValue={query?.time} name="time">
                      <option value="ALL">Time</option>
                      <option value="TODAY">Today</option>
                      <option value="THIS_WEEK">This Week</option>
                      <option value="THIS_WEEKEND">This Weekend</option>
                      <option value="ALL">All</option>
                    </select>
                  </div>
                  <div className="custom-select category-select filter-options mt-3">
                    <select defaultValue={query?.type} name="type">
                      <option value="ALL">Event Type</option>
                      <option value={"ONLINE"}>Online</option>
                      <option value={"OFFLINE"}>Offline</option>
                      <option value={"ALL"}>All</option>
                    </select>
                  </div>
                  <button
                    className="primary-btn-fill w-100 mt-4 d-flex gap-2 justify-content-center align-items-center rounded-pill"
                    type="submit"
                  >
                    <i className="bi bi-search" />
                    Search
                  </button>
                  <Link to={`/event`} onClick={scrollTop}>
                    <button
                      className="primary-btn-outline w-100 mt-2 d-flex gap-2 justify-content-center align-items-center rounded-pill"
                      type="reset"
                    >
                      Reset
                    </button>
                  </Link>
                </form>
                <div className="sb-category">
                  <div className="category-title">
                    <i className="bi bi-list-task" /> <h4>Category</h4>
                  </div>
                  {categories.length > 0 ? (
                    <ul className="category-list">
                      {categories.map((category, index) => (
                        <li
                          key={`category-${category.id}`}
                          className={
                            selectedCategory == category.id ? "selected" : ""
                          }
                        >
                          <a
                            role="button"
                            onClick={() => handleCategoryClick(category)}
                          >
                            {category.name}
                            <div className="category-lavel">
                              <span>{category.total_event}</span>
                              <i className="bi bi-box-arrow-right" />
                            </div>
                          </a>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p className="fs-5 py-4 ms-3">No categories found</p>
                  )}
                </div>
                <div className="sb-tags">
                  <div className="category-title">
                    <i className="bi bi-tags" /> <h4>Tags</h4>
                  </div>
                  <ul className="tag-list">
                    {tags.map((tag, idx) => (
                      <li key={tag.hashtags + idx}>
                        <Link
                          className={
                            query.tags === tag.hashtags ? "active" : ""
                          }
                          onClick={scrollTop}
                          to={handleTagClick(tag)}
                        >
                          {tag.hashtags}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
                <PromotionBannerArea data={banners} />
              </div>
            </div>
            <div className="col-lg-8">
              {loading ? (
                <div className="w-100 d-flex justify-content-center mt-5 pt-5">
                  <div
                    className="spinner-border ms-2"
                    style={{ width: "3rem", height: "3rem" }}
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : (
                <>
                  {events.length > 0 ? (
                    <div className="row">
                      {paginatedEvents.map((event, index) => (
                        // moment(renderNextEvent(event.timeslots)).isAfter(
                        //   moment()
                        // ) &&
                        <div
                          className="col-xl-6 col-lg-12 col-md-6 wow fadeInUp animated"
                          data-wow-delay={`${wowDelay(index)}ms`}
                          data-wow-duration="1500ms"
                          key={"event-card-" + event.id}
                        >
                          <div className="event-card-md">
                            <div className="event-thumb">
                              <img
                                src={event.image || EventThumb1}
                                alt="Imgs"
                                onError={handleImageError}
                              />
                              <div className="event-lavel" title={event.type}>
                                <i className="bi bi-diagram-3" />
                                <span>{event.type}</span>
                              </div>
                            </div>
                            <div className="event-content">
                              <div className="event-info">
                                <div className="event-date">
                                  <div>
                                    <i className="bi bi-clock"></i>{" "}
                                    {event.timeslots?.length > 0 ? (
                                      moment(
                                        renderNextEvent(event.timeslots)
                                      ).isAfter(moment()) ? (
                                        moment(
                                          renderNextEvent(event.timeslots)
                                        ).format("MMM DD, YYYY, hh:mm a")
                                      ) : (
                                        <span className="text-danger ms-2 fw-medium">
                                          Event Ended
                                        </span>
                                      )
                                    ) : (
                                      "-"
                                    )}
                                  </div>
                                </div>
                                <div className="event-location">
                                  <div>
                                    <i className="bi bi-geo-alt" />{" "}
                                    {event.offline_address ||
                                      event.online_address ||
                                      "-"}
                                  </div>
                                </div>
                              </div>
                              <h5 className="event-title">
                                <Link
                                  onClick={scrollTop}
                                  to={`${process.env.PUBLIC_URL}/event-details/${event.id}`}
                                >
                                  {event.name}
                                </Link>
                              </h5>
                              <div className="event-bottom">
                                <div className="event-readme">
                                  <Link
                                    to={`${process.env.PUBLIC_URL}/event-details/${event.id}#event-date`}
                                    // onClick={scrollTop}
                                    className="primary-btn-fill btn-sm btn-sm"
                                  >
                                    Get Ticket
                                  </Link>
                                </div>
                                <h5 className="event-price-tag">
                                  {formatPrice(event.tickets)}
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                      <div className="custom-pagination text-center">
                        <ul className="page-list">
                          <li className="has-arrow">
                            <a
                              role="button"
                              className={currentPage > 1 ? "" : "disabled"}
                              onClick={() =>
                                currentPage > 1 && handlePageChange(-1)
                              }
                            >
                              <i className="bi bi-chevron-left" />
                            </a>
                          </li>
                          <li className="has-arrow">
                            <a
                              role="button"
                              className={
                                currentPage < Math.ceil(events.length / perpage)
                                  ? ""
                                  : "disabled"
                              }
                              onClick={() =>
                                currentPage <
                                  Math.ceil(events.length / perpage) &&
                                handlePageChange(1)
                              }
                            >
                              <i className="bi bi-chevron-right" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  ) : (
                    <div className="mt-5 pt-5 d-flex justify-content-center">
                      <h4 className="fs-1 text-secondary text-uppercase fw-bold">
                        No events found
                      </h4>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <CollectionArea />
        </div>
      </div>
      {/* ===============  Event Area end  =============== */}
    </>
  );
};

export default EventSidebar;

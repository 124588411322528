import React from "react";
import EventDetailsLeftArea from "./EventDetailsLeftArea";
import EventRightArea from "./EventRightArea";
import moment from "moment";
import EventEnd from "../../../../assets/images/event/event-end.png";
import { Link, withRouter } from "react-router-dom/cjs/react-router-dom.min";
import {
  addEventToWishlist,
  fetchMemberWishlist,
  fetchWishlist,
  removeEventFromWishlist,
} from "../../../../services/wishlist";
import { useAtom } from "jotai";
import { wishlistAtom } from "../../../../store/Wishlist";
import { isAuthenticated } from "../../../../utils/auth-check";

const EventDetailsWrapper = (props) => {
  const isLoggedIn = isAuthenticated();
  const [wishlist, setWishlist] = useAtom(wishlistAtom);
  const addTowishlist = async () => {
    try {
      const response = await addEventToWishlist(props.data.id);
      if (response.status === 201) {
        await getWishlist();
      }
    } catch (error) {
      console.error(error);
    }
  };
  const removeWishlist = async () => {
    try {
      const response = await removeEventFromWishlist(props.data.id);
      if (response.status === 200) {
        await getWishlist();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getWishlist = async () => {
    try {
      const response = await fetchMemberWishlist();
      if (response.status === 200) {
        setWishlist(response.data.data);
      }
    } catch (error) {
      if (error.response.status === 404) {
        setWishlist([]);
      }
      console.error(error);
    }
  };
  const timeEvent = () => {
    const { data: eventDetails } = props;
    if (!eventDetails.timeslots?.length) return {};
    const sortedStartTimeslots = eventDetails.timeslots?.sort((a, b) => {
      return (
        new Date(a.start_date).getTime() - new Date(b.start_date).getTime()
      );
    });
    const sortedEndTimeslots = eventDetails.timeslots?.sort((a, b) => {
      return new Date(a.end_date).getTime() - new Date(b.end_date).getTime();
    });
    const nearest = sortedStartTimeslots.find(
      (timeslot) =>
        new Date(timeslot.start_date).getTime() >= new Date().getTime()
    );
    return {
      start: sortedStartTimeslots[0].start_date,
      end: sortedEndTimeslots[sortedEndTimeslots.length - 1].end_date,
      nearest: nearest?.start_date,
    };
  };
  const checkLoggedIn = () => {
    if (!isLoggedIn) {
      const currentPath = props.location.pathname;
      const currentSearch = props.location.search;
      const searchParams = new URLSearchParams(currentSearch);
      searchParams.set("login", 1);
      props.history.push(`${currentPath}?${searchParams.toString()}`);
      // props.history.push();
    }
  };

  const { data: eventDetails } = props;
  const {
    start: eventStart,
    end: eventEnd,
    nearest: nearestEvent,
  } = eventDetails && timeEvent();
  const isEventAvailable = new Date(eventEnd).getTime() >= new Date().getTime();
  const isAddedToWishlist = wishlist?.find(
    (event) => event.event_id === eventDetails.id
  );
  return (
    <>
      <div className="event-details-wrapper">
        <div className="gray-300">
          <div className="container">
            <div className="d-xl-flex align-items-center gap-5">
              <div className="event-detail-header d-flex col-xl-8 gap-5 align-items-center py-5 position-relative">
                {isEventAvailable ? (
                  <div className="event-date-container text-center">
                    <h5>{moment(nearestEvent).format("MMMM")}</h5>
                    <h2 className="event-date">
                      {moment(nearestEvent).format("DD")}
                    </h2>
                    <h5>{moment(nearestEvent).format("YYYY")}</h5>
                  </div>
                ) : eventDetails.timeslots?.length > 0 ? (
                  <div className="event-date-container text-center my-auto">
                    <img src={EventEnd} alt="event-end" width={48} />
                    <h5 className="mt-3">Event Ended</h5>
                  </div>
                ) : null}
                <div>
                  <h3 className="event-title mb-3">{eventDetails.name}</h3>
                  <div className="event-time">
                    <i className="bi bi-clock"></i>{" "}
                    {eventDetails.timeslots?.length > 0 ? (
                      <span>
                        {moment(eventStart).format("ddd, MMMM DD, YYYY")} -{" "}
                        {moment(eventEnd).format("ddd, MMMM DD, YYYY")}
                      </span>
                    ) : (
                      <span>No Event Scheduled</span>
                    )}
                  </div>
                  <div className="event-location">
                    <i className="bi bi-geo-alt"></i>{" "}
                    {eventDetails.offline_address ||
                      eventDetails.online_address ||
                      "-"}
                  </div>
                </div>
              </div>
              {isEventAvailable && (
                <div className="col-xl">
                  <div>
                    <a href="#event-date" onClick={checkLoggedIn}>
                      <button className="btn primary-btn-fill w-100 btn-lg">
                        Book Now
                      </button>
                    </a>
                  </div>
                  <div className="mt-3">
                    <button
                      className="btn primary-btn-outline w-100 btn-lg"
                      style={{ whiteSpace: "nowrap" }}
                      onClick={() => {
                        !isLoggedIn
                          ? checkLoggedIn()
                          : !isAddedToWishlist
                          ? addTowishlist()
                          : removeWishlist();
                      }}
                    >
                      {isAddedToWishlist ? (
                        <>
                          <i className="bi bi-heart-fill me-2"></i>
                          Remove from Wishlist
                        </>
                      ) : (
                        <>
                          <i className="bi bi-heart me-2"></i>
                          Add to Wishlist
                        </>
                      )}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="container position-relative">
          {/* <div className="background-title text-style-one">
              <h2>Event Details</h2>
            </div> */}
          <div className="row">
            {/* event detils child componet */}
            <EventDetailsLeftArea data={eventDetails} />
            <EventRightArea data={eventDetails} />
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(EventDetailsWrapper);

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { fetchContents } from "../../../services/contents";
class PrivacyPolicyPage extends Component {
  constructor() {
    super();
    this.state = {
      privacyPolicy: {},
    };
  }
  componentDidMount() {
    this.getContents();
  }
  scrollTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  async getContents() {
    try {
      const response = await fetchContents();
      const privacyPolicy = response.data?.data.find(
        (content) => content.type === "Privacy Policy"
      );
      this.setState({ privacyPolicy });
    } catch (error) {
      console.error(error);
    }
  }
  render() {
    const { privacyPolicy } = this.state;
    return (
      <>
        {/* ===============  breadcrumb area start =============== */}
        <div className="breadcrumb-area">
          <div className="container">
            <div className="row align-items-end">
              <div className="col-lg-12">
                <div className="breadcrumb-content">
                  <div className="page-outlined-text">
                    <h1>Privacy Policy</h1>
                  </div>
                  <h2 className="page-title">Privacy Policy</h2>
                  <ul className="page-switcher">
                    <li>
                      <Link
                        onClick={this.scrollTop}
                        to={`${process.env.PUBLIC_URL}/`}
                      >
                        Home <i className="bi bi-caret-right" />
                      </Link>
                    </li>
                    <li>Privacy Policy</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ===============  breadcrumb area end =============== */}
        {/* ===============  Privacy Policy wrapper start =============== */}
        <div className="tnc-wrapper overflow-hidden">
          <div className="container pt-120 position-relative">
            <div className="background-title text-style-one">
              <h2>Privacy Policy</h2>
            </div>
            <div>
              <h3 className="fs-2 fw-bold">Privacy Policy</h3>
              <div className="mt-5">
                <div
                  dangerouslySetInnerHTML={{ __html: privacyPolicy.content }}
                />
              </div>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-lg-12">
              <div className="privacy-policy-map-wrap mt-120">
                <div className="mapouter">
                  <div className="gmap_canvas">
                    <iframe
                      title="Video"
                      id="gmap_canvas"
                      src="https://maps.google.com/maps?q=2880%20Broadway,%20New%20York&t=&z=9&ie=UTF8&iwloc=&output=embed"
                    />
                    <Link
                      onClick={this.scrollTop}
                      to={"https://123movies-to.org"}
                    />
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        {/* ===============  Privacy Policy wrapper end =============== */}
      </>
    );
  }
}

export default PrivacyPolicyPage;

import React, { Component } from "react";
import ModalVideo from "react-modal-video";
// page animation
import WOW from "wowjs";
import "../../../assets/css/animate.css";
import AboutVideo from "../../../assets/images/about/a-mini-video.png";
// mini gallary
import MiniGallary1 from "../../../assets/images/about/a-mini1.png";
import MiniGallary2 from "../../../assets/images/about/a-mini2.png";
// icon image
import QuoteIcon from "../../../assets/images/icons/qoute-icon.png";
// feature icon image import
import FeatureIcon3 from "../../../assets/images/icons/gaust-md.png";
import FeatureIcon2 from "../../../assets/images/icons/sound-md.png";
import FeatureIcon1 from "../../../assets/images/icons/speaker-md.png";
import VideoShapeIcon from "../../../assets/images/shapes/play-btn.png";
import { fetchAboutContents } from "../../../services/contents";

const CONTENTS = {
  "About Us": "about",
  "Why Choose Us": "whyChooseUs",
  Story: "story",
};

class AboutWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      contents: {
        about: {},
        whyChooseUs: {},
        story: {},
      },
    };
  }

  componentDidMount() {
    // animation script
    this.getContents("About Us");
    this.getContents("Why Choose Us");
    this.getContents("Story");
    new WOW.WOW().init();
  }
  async getContents(type) {
    try {
      const response = await fetchAboutContents(type);
      const content = response.data?.data;
      this.setState({
        contents: {
          ...this.state.contents,
          [CONTENTS[type]]: content,
        },
      });
    } catch (error) {
      console.error(error);
    }
  }
  render() {
    // modal video change state
    const { isOpen, contents } = this.state;
    return (
      <>
        {/* =============== About wrapper start =============== */}
        <div className="about-wrapper mt-96">
          <div className="container">
            <div className="about-company">
              <div className="row">
                <div className="col-lg-6">
                  <div className="company-info">
                    <h5>{contents.about.title}</h5>
                    {/* <div
                      dangerouslySetInnerHTML={{
                        __html: contents.about.content,
                      }}
                    ></div> */}
                    <div className="mt-4 about-us-content">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: contents.about.content,
                        }}
                      ></div>
                    </div>
                    {/* <div className="row">
                      <div className="col-sm-4 col-6">
                        <div className="about-infobox">
                          <div className="info-icon">
                            <img src={Icon1} alt="Imgs" />
                          </div>
                          <div className="info-content">
                            <h3>
                              <CountUp
                                start={0}
                                end={100}
                                duration={4.75}
                              ></CountUp>
                              +
                            </h3>
                            <h6>Speaker</h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-4 col-6">
                        <div className="about-infobox">
                          <div className="info-icon">
                            <img src={Icon2}  alt="Imgs" />
                          </div>
                          <div className="info-content">
                            <h3>
                              <CountUp
                                start={0}
                                end={400}
                                duration={3.75}></CountUp>
                              +
                            </h3>
                            <h6>Event</h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-4 col-6">
                        <div className="about-infobox">
                          <div className="info-icon">
                            <img src={Icon3} alt="Imgs" />
                          </div>
                          <div className="info-content">
                            <h3>
                              <CountUp
                                start={0}
                                end={100}
                                duration={4.75}
                              ></CountUp>
                              +
                            </h3>
                            <h6>Schedule</h6>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    {/* <div className="about-more-btn">
                      <Link
                        onClick={this.scrollTop}
                        to={"#"}
                        className="primary-btn-fill"
                      >
                        Read More
                      </Link>
                    </div> */}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="company-mini-gallary">
                    <div className="Gallary-item1 Gallary-item">
                      <img
                        src={MiniGallary1}
                        alt="Imgs"
                        className="img-fluid"
                      />
                    </div>
                    <div className="Gallary-item2 Gallary-item">
                      <img
                        src={MiniGallary2}
                        alt="Imgs"
                        className="img-fluid"
                      />
                    </div>
                    <div className="Gallary-item3 Gallary-item Gallary-video">
                      <img
                        src={AboutVideo}
                        alt="abput vedio"
                        className="img-fluid"
                      />
                      <div className="video-icon">
                        <i
                          onClick={() => this.setState({ isOpen: true })}
                          className="
                      video-popup"
                        >
                          <img src={VideoShapeIcon} alt="Imgs" />
                        </i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="company-feature mt-96">
              <div className="row">
                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="company-feature-box">
                        <div className="feature-icon">
                          <img src={FeatureIcon1} alt="Imgs" />
                        </div>
                        <h5>we all time Provide Intelligent Speaker</h5>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="company-feature-box">
                        <div className="feature-icon">
                          <img src={FeatureIcon2} alt="Imgs" />
                        </div>
                        <h5>A good answer will Best knowledge</h5>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="company-feature-box">
                        <div className="feature-icon">
                          <img src={FeatureIcon3} alt="Imgs" />
                        </div>
                        <h5>we are always dedicated our Guest</h5>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="company-feature-box">
                        <div className="feature-icon">
                          <img src={FeatureIcon1} alt="Imgs" />
                        </div>
                        <h5>we are provide friendly environment</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="feature-info">
                    <h5 className="h5 fw-bold">{contents.whyChooseUs.title}</h5>
                    <h2>{contents.whyChooseUs.subtitle}</h2>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: contents.whyChooseUs.content,
                      }}
                    ></div>
                    <div className="feature-list row ">
                      <div className="col-xl-4 col-sm-6">
                        <div className="single-feature">
                          <i className="bi bi-check" />{" "}
                          <span>Free Certificate</span>
                        </div>
                      </div>
                      <div className="col-xl-4 col-sm-6">
                        <div className="single-feature">
                          <i className="bi bi-check" />{" "}
                          <span>Conference Lunch</span>
                        </div>
                      </div>
                      <div className="col-xl-4 col-sm-6">
                        <div className="single-feature">
                          <i className="bi bi-check" />{" "}
                          <span>Conference Snaks</span>
                        </div>
                      </div>
                      <div className="col-xl-4 col-sm-6">
                        <div className="single-feature">
                          <i className="bi bi-check" />{" "}
                          <span>24/7 Support </span>
                        </div>
                      </div>
                      <div className="col-xl-4 col-sm-6">
                        <div className="single-feature">
                          <i className="bi bi-check" />{" "}
                          <span>All Type Event</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="about-history position-relative mt-120">
              <h3 className="float-title position-absolute">Wii Union Story</h3>
              <div className="qoute-icon position-absolute">
                <img src={QuoteIcon} alt="Imgs" />
              </div>
              <div className="about-history-content">
                <div
                  dangerouslySetInnerHTML={{ __html: contents.story.content }}
                ></div>
              </div>
            </div>
          </div>
        </div>
        {/* =============== About wrapper end =============== */}
        <React.Fragment>
          <ModalVideo
            channel="youtube"
            autoplay
            isOpen={isOpen}
            videoId="-tJYN-eG1zk"
            onClose={() => this.setState({ isOpen: false })}
          />
        </React.Fragment>
      </>
    );
  }
}

export default AboutWrapper;

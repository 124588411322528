import React, { Component } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
// Swiper slider imported
import SwiperCore, { Navigation } from "swiper";
import "swiper/components/effect-fade/effect-fade.min.css";
import "swiper/components/navigation/navigation.min.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import "swiper/swiper.min.css";
import WOW from "wowjs";
import "../../../assets/css/animate.css";
// image import
import HeroIMG1 from "../../../assets/images/hero/hero-figure1.png";
import HeroIMG2 from "../../../assets/images/shapes/hero-animi.png";
import ImageShape from "../../../assets/images/shapes/hero-shape.png";
import SpeakerThumb2 from "../../../assets/images/speaker/sb-speaker1.png";
import SpeakerThumb1 from "../../../assets/images/speaker/sb-speaker2.png";
import SpeakerThumb3 from "../../../assets/images/speaker/sb-speaker3.png";
import SpeakerThumb4 from "../../../assets/images/speaker/sb-speaker4.png";
import SpeakerThumb5 from "../../../assets/images/speaker/sb-speaker5.png";
import { fetchEventCategories } from "../../../services/events";
import { initScripts } from "../../../utils";
import { fetchBanners } from "../../../services/banners";

// install Swiper modules
SwiperCore.use([Navigation]);
class HeroArea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(),
      eventCategories: [],
      banners: [],
    };
  }
  componentDidMount() {
    new WOW.WOW().init();
    this.getPromotionBanners();
    this.getEventCategories();
  }
  scrollTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  // changeDatepickerHaldeller = (date) => {
  //   this.setState({ startDate: date });
  // };
  handleSearch(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    const urlParams = new URLSearchParams(formData);
    window.location.href = `${
      process.env.PUBLIC_URL
    }/event?${urlParams.toString()}`;
  }
  getEventCategories = async () => {
    try {
      const response = await fetchEventCategories();
      this.setState({ eventCategories: response.data.data });
      initScripts();
    } catch (error) {
      console.error(error);
    }
  };
  getPromotionBanners = async () => {
    try {
      const response = await fetchBanners();
      this.setState({ banners: response.data.data });
    } catch (error) {
      console.log(error);
    }
  };
  handleErrorBanner(e) {
    e.target.onerror = null;
    e.target.src = HeroIMG1;
  }
  render() {
    const { eventCategories, banners } = this.state;
    const sliderInit = {
      slidesPerView: 1,
      loop: true,
      speed: 2400,
      effect: "slide",
      nav: "true",
      autoplay: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      pagination: {
        el: ".hero-pagination",
        type: "custom",
        clickable: true,
        renderCustom: function (swiper, current, total) {
          function numberAppend(d) {
            return d < 10 ? "0" + d.toString() : d.toString();
          }
          return numberAppend(current);
        },
      },
    };
    return (
      <>
        <div className="main-slider-wrapper">
          {/* ===============  hero area start  =============== */}
          <div className="hero-area">
            <div className="hero-shape">
              <img src={ImageShape} alt="HeroIMG" />
            </div>
            <div className="hero-sidebar-area d-lg-block d-none">
              <div className="sidebar-list">
                <div className="sb-single-speaker">
                  <div className="single-speaker-thumb">
                    <img src={SpeakerThumb2} alt="HeroIMG" />
                  </div>
                  <div className="sb-speaker-content">
                    <div className="sb-speaker-info">
                      <h6>
                        <Link
                          onClick={this.scrollTop}
                          to={`${process.env.PUBLIC_URL}/speaker-details`}
                        >
                          Rodrigo Ema
                        </Link>
                      </h6>
                      <span>Marketing Ex</span>
                    </div>
                    <ul className="sb-speaker-social-cion">
                      <li>
                        <Link onClick={this.scrollTop} to={"#"}>
                          <i className="fab fa-facebook-f" />
                        </Link>
                      </li>
                      <li>
                        <Link onClick={this.scrollTop} to={"#"}>
                          <i className="fab fa-instagram" />
                        </Link>
                      </li>
                      <li>
                        <Link onClick={this.scrollTop} to={"#"}>
                          <i className="fab fa-linkedin-in" />
                        </Link>
                      </li>
                      <li>
                        <Link onClick={this.scrollTop} to={"#"}>
                          <i className="fab fa-twitter" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="sb-single-speaker">
                  <div className="single-speaker-thumb">
                    <img src={SpeakerThumb1} alt="HeroIMG" />
                  </div>
                  <div className="sb-speaker-content">
                    <div className="sb-speaker-info">
                      <h6>
                        <Link
                          onClick={this.scrollTop}
                          to={`${process.env.PUBLIC_URL}/speaker-details`}
                        >
                          Aderson Jr
                        </Link>
                      </h6>
                      <span>Marketing Ex</span>
                    </div>
                    <ul className="sb-speaker-social-cion">
                      <li>
                        <Link onClick={this.scrollTop} to={"#"}>
                          <i className="fab fa-facebook-f" />
                        </Link>
                      </li>
                      <li>
                        <Link onClick={this.scrollTop} to={"#"}>
                          <i className="fab fa-instagram" />
                        </Link>
                      </li>
                      <li>
                        <Link onClick={this.scrollTop} to={"#"}>
                          <i className="fab fa-linkedin-in" />
                        </Link>
                      </li>
                      <li>
                        <Link onClick={this.scrollTop} to={"#"}>
                          <i className="fab fa-twitter" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="sb-single-speaker">
                  <div className="single-speaker-thumb">
                    <img src={SpeakerThumb3} alt="HeroIMG" />
                  </div>
                  <div className="sb-speaker-content">
                    <div className="sb-speaker-info">
                      <h6>
                        <Link
                          onClick={this.scrollTop}
                          to={`${process.env.PUBLIC_URL}/speaker-details`}
                        >
                          Mr. Avater
                        </Link>
                      </h6>
                      <span>Marketing Ex</span>
                    </div>
                    <ul className="sb-speaker-social-cion">
                      <li>
                        <Link onClick={this.scrollTop} to={"#"}>
                          <i className="fab fa-facebook-f" />
                        </Link>
                      </li>
                      <li>
                        <Link onClick={this.scrollTop} to={"#"}>
                          <i className="fab fa-instagram" />
                        </Link>
                      </li>
                      <li>
                        <Link onClick={this.scrollTop} to={"#"}>
                          <i className="fab fa-linkedin-in" />
                        </Link>
                      </li>
                      <li>
                        <Link onClick={this.scrollTop} to={"#"}>
                          <i className="fab fa-twitter" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="sb-single-speaker">
                  <div className="single-speaker-thumb">
                    <img src={SpeakerThumb4} alt="HeroIMG" />
                  </div>
                  <div className="sb-speaker-content">
                    <div className="sb-speaker-info">
                      <h6>
                        <Link
                          onClick={this.scrollTop}
                          to={`${process.env.PUBLIC_URL}/speaker-details`}
                        >
                          J.J Walter
                        </Link>
                      </h6>
                      <span>Marketing Ex</span>
                    </div>
                    <ul className="sb-speaker-social-cion">
                      <li>
                        <Link onClick={this.scrollTop} to={"#"}>
                          <i className="fab fa-facebook-f" />
                        </Link>
                      </li>
                      <li>
                        <Link onClick={this.scrollTop} to={"#"}>
                          <i className="fab fa-instagram" />
                        </Link>
                      </li>
                      <li>
                        <Link onClick={this.scrollTop} to={"#"}>
                          <i className="fab fa-linkedin-in" />
                        </Link>
                      </li>
                      <li>
                        <Link onClick={this.scrollTop} to={"#"}>
                          <i className="fab fa-twitter" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="sb-single-speaker">
                  <div className="single-speaker-thumb">
                    <img src={SpeakerThumb5} alt="HeroIMG" />
                  </div>
                  <div className="sb-speaker-content">
                    <div className="sb-speaker-info">
                      <h6>
                        <Link
                          onClick={this.scrollTop}
                          to={`${process.env.PUBLIC_URL}/speaker-details`}
                        >
                          Camila Rido
                        </Link>
                      </h6>
                      <span>Marketing Ex</span>
                    </div>
                    <ul className="sb-speaker-social-cion">
                      <li>
                        <Link onClick={this.scrollTop} to={"#"}>
                          <i className="fab fa-facebook-f" />
                        </Link>
                      </li>
                      <li>
                        <Link onClick={this.scrollTop} to={"#"}>
                          <i className="fab fa-instagram" />
                        </Link>
                      </li>
                      <li>
                        <Link onClick={this.scrollTop} to={"#"}>
                          <i className="fab fa-linkedin-in" />
                        </Link>
                      </li>
                      <li>
                        <Link onClick={this.scrollTop} to={"#"}>
                          <i className="fab fa-twitter" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <h5>Meet Our Best Spekar:</h5>
              </div>
            </div>
            <div className="">
              <div className="swiper-container hero-slider overflow-hidden">
                <Swiper {...sliderInit} className="swiper-wrapper">
                  {banners.map((banner) => (
                    <SwiperSlide
                      className="swiper-slide position-relative"
                      key={`hero-banner-${banner.id}`}
                    >
                      <a
                        href={banner.action_url}
                        target="_blank"
                        rel="noreferrer"
                        className="hero-banner-link"
                      >
                        <img
                          src={banner.image || HeroIMG1}
                          alt="HeroIMG"
                          className="hero-banner"
                          onError={this.handleErrorBanner}
                        />
                      </a>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
            {/* <div className="slider-arrows text-center d-lg-block d-none">
              <div
                className="swiper-button-next"
                tabIndex={0}
                role="button"
                aria-label="Next slide"
              >
                <i className="bi bi-chevron-up" />
              </div>
              <div className="hero-pagination d-block w-auto" />
              <div
                className="swiper-button-prev"
                tabIndex={0}
                role="button"
                aria-label="Previous slide"
              >
                <i className="bi bi-chevron-down" />
              </div>
            </div> */}
          </div>
          {/* ===============  hero area end  =============== */}
          {/* ===============  main searchbar area start  =============== */}
          <div className="main-searchbar-area">
            <div className="container">
              <form className="searchbar-wrapper" onSubmit={this.handleSearch}>
                <div className="row">
                  <div className="col-lg-10">
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="searchbar-input-group">
                          <input
                            type="text"
                            name="q"
                            placeholder="Event Name....."
                            id="search-event"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="searchbar-input-group">
                          {/* <input
                            type="text"
                            id="datepicker"
                            placeholder="Date"
                          /> */}
                          {/* <DatePicker
                            selected={startDate}
                            onChange={(date) =>
                              this.changeDatepickerHaldeller(date)
                            }
                            placeholderText="Date"
                          /> */}
                          <div className="custom-select time-select filter-options">
                            <select name="time">
                              <option value="ALL">Time</option>
                              <option value="TODAY">Today</option>
                              <option value="THIS_WEEK">This Week</option>
                              <option value="THIS_WEEKEND">This Weekend</option>
                              <option value="ALL">All</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="searchbar-input-group">
                          <div className="custom-select filter-options">
                            <select name="category_id">
                              <option value="ALL">Category</option>
                              {eventCategories.map((category) => (
                                <option key={category.id} value={category.id}>
                                  {category.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2">
                    <div className="search-submit">
                      <input
                        type="submit"
                        value="Search Now"
                        placeholder="Search Now"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          {/* ===============  main searchbar area end  =============== */}
        </div>
      </>
    );
  }
}

export default HeroArea;

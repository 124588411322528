import React, { Component } from "react";
import { Link } from "react-router-dom";
// Swiper slider imported
import SwiperCore, {
  Autoplay,
  EffectFade,
  Navigation,
  Pagination,
} from "swiper";
import "swiper/components/effect-fade/effect-fade.min.css";
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import "swiper/swiper.min.css";
// image import
import EventThumb1 from "../../../../assets/images/banners/head-banner.jpg";
import { fetchEvents } from "../../../../services/events";
import moment from "moment";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
// install Swiper modules
SwiperCore.use([Autoplay, Pagination, Navigation, EffectFade]);
class RelatedEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
    };
  }
  componentDidUpdate(prevProps) {
    if (prevProps.details !== this.props.details) {
      this.getEventList();
    }
  }
  scrollTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  getEventList = async () => {
    try {
      const { details } = this.props;
      const response = await fetchEvents({
        category_id: details.event_category_id,
      });
      const relatedEvents =
        response.data.data?.filter((event) => event.id !== details.id) || [];
      this.setState({ events: relatedEvents });
    } catch (error) {
      console.error(error);
    }
  };
  formatPrice = (tickets) => {
    const prices = tickets.map((ticket) => ticket.price);
    if (prices.length === 0) {
      return "No Ticket Available";
    }
    const minPrice = Math.min(...prices);
    const maxPrice = Math.max(...prices);
    return minPrice === maxPrice
      ? `HKD $${minPrice}`
      : `HKD $${minPrice} - HKD $${maxPrice}`;
  };
  renderNextEvent(timeslots) {
    const sortedEventByTime = timeslots.sort((a, b) =>
      moment(a.start_date).diff(moment(b.start_date), "hours")
    );
    const pastEvent = sortedEventByTime[0];
    const upcomingEvent = sortedEventByTime[sortedEventByTime.length - 1];
    if (upcomingEvent) return upcomingEvent.start_date;
    return pastEvent.start_date;
  }
  handleGoToEventDetails = (id) => {
    this.props.history.push(`/event-details/${id}`);
    this.scrollTop();
  };
  render() {
    const { events } = this.state;
    const relatedEventSlider = {
      slidesPerView: 1,
      speed: 1000,
      spaceBetween: 24,
      loop: events.length > 3 ? true : false,
      roundLengths: true,
      autoplay: {
        delay: 12000,
      },
      navigation: {
        nextEl: ".blog-next",
        prevEl: ".blog-prev",
      },
      breakpoints: {
        300: {
          slidesPerView: 1,
        },
        480: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 2,
        },
        992: {
          slidesPerView: 2,
        },
        1200: {
          slidesPerView: 3,
        },
      },
    };
    if (events.length === 0) return null;
    return (
      <>
        {/* ===============  recent event start =============== */}

        <div className="recent-event-wrap mt-110">
          <div className="container">
            <h3>Related Event</h3>
            <div className="recent-event-slider swiper">
              <Swiper {...relatedEventSlider} className="swiper-wrapper">
                {events.map((event) => (
                  <SwiperSlide
                    key={`recent-event-${event.id}`}
                    className="swiper-slide"
                  >
                    <div className="event-card-md">
                      <div className="event-thumb">
                        <img src={event.image || EventThumb1} alt="Imgs" />
                        <div className="event-lavel" title={event.hashtags}>
                          <i className="bi bi-diagram-3" />
                          <span>{event.type}</span>
                        </div>
                      </div>
                      <div className="event-content">
                        <div className="event-info">
                          <div className="event-date">
                            <div>
                              <i className="bi bi-clock"></i>{" "}
                              {event.timeslots?.length > 0 ? (
                                moment(
                                  this.renderNextEvent(event.timeslots)
                                ).isAfter(moment()) ? (
                                  moment(
                                    this.renderNextEvent(event.timeslots)
                                  ).format("MMM DD, YYYY, hh:mm a")
                                ) : (
                                  <span className="text-danger ms-2 fw-medium">
                                    Event Ended
                                  </span>
                                )
                              ) : (
                                "-"
                              )}
                            </div>
                          </div>
                          <div className="event-location">
                            <div>
                              <i className="bi bi-geo-alt" />{" "}
                              {event.offline_address ||
                                event.online_address ||
                                "-"}
                            </div>
                          </div>
                        </div>
                        <h5 className="event-title">
                          <div
                            role="button"
                            onClick={() =>
                              this.handleGoToEventDetails(event.id)
                            }
                          >
                            {event.name}
                          </div>
                        </h5>
                        <div className="event-bottom">
                          <div
                            className="event-readme"
                            onClick={() =>
                              this.handleGoToEventDetails(event.id)
                            }
                          >
                            <button className="primary-btn-fill btn-sm btn-sm">
                              Get Ticket
                            </button>
                          </div>
                          <h5 className="event-price-tag">
                            {this.formatPrice(event.tickets)}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
        {/* ===============  recent event end =============== */}
      </>
    );
  }
}

export default withRouter(RelatedEvent);

import { useAtom } from "jotai";
import Modal from "./Modal";
import { wishlistAtom } from "../../../store/Wishlist";
import { Link, withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { removeEventFromWishlist } from "../../../services/wishlist";
const ModalWishlist = ({ isOpen = false, onClose, ...props }) => {
  const [wishlist, setWishlist] = useAtom(wishlistAtom);
  const handleRemoveEventFromWishlist = async (eventId) => {
    try {
      const response = await removeEventFromWishlist(eventId);
      if (response.status === 200) {
        setWishlist((prev) => {
          return prev.filter((item) => item.event_id !== eventId);
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Modal title="My Wishlist" isOpen={isOpen} onClose={onClose}>
      <div className="my-wishlist-container font-barlow">
        {wishlist?.length ? (
          <div className="d-flex flex-column gap-3">
            {wishlist.map((item, index) => (
              <div
                key={index}
                className="d-flex gap-3 align-items-center w-100"
              >
                <div className="card w-100">
                  <div className="card-header">
                    <h5 className="fw-bold">{item.event?.name}</h5>
                  </div>
                  <div className="card-body d-flex gap-2">
                    <button
                      className="primary-btn-fill btn-sm col"
                      onClick={() =>
                        props.history.push(`/event-details/${item.event_id}`)
                      }
                    >
                      Go to Event
                    </button>
                    <button
                      className="primary-btn-outline btn-sm col"
                      onClick={() =>
                        handleRemoveEventFromWishlist(item.event_id)
                      }
                    >
                      Remove
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="text-center">Your wishlist is empty</div>
        )}
      </div>
      <div className="w-100 mt-5">
        <button className="primary-btn-outline w-100" onClick={onClose}>
          Close
        </button>
      </div>
    </Modal>
  );
};

export default withRouter(ModalWishlist);
